import * as actionTypes from '../ActionTypes';

const initialState = {
  setLoading: {
    flyoutLoading: false,
    innerLoading: false,
    authLoading: false,
  },
  successMessage: null,
  errorMessage: null,
  authMessages: {
    errorMessage: null,
  },
  adminMessages: {
    errorMessage: '',
  },
};

const messageLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.message,
      };
    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.message,
      };
    case actionTypes.SET_AUTH_ERROR_MESSAGE:
      return {
        ...state,
        authMessages: {
          ...state.authMessages,
          errorMessage: action.message,
        },
      };
    case actionTypes.IS_FLYOUT_LOADING:
      return {
        ...state,
        setLoading: {
          ...state.setLoading,
          flyoutLoading: action.toggleValue,
        },
      };
    case actionTypes.IS_AUTH_LOADING:
      return {
        ...state,
        setLoading: {
          ...state.setLoading,
          authLoading: action.toggleValue,
        },
      };
    case actionTypes.IS_INNER_LOADING:
      return {
        ...state,
        setLoading: {
          ...state.setLoading,
          innerLoading: action.toggleValue,
        },
      };
    case actionTypes.SET_FLYOUT_ERROR_MESSAGE:
      return {
        ...state,
        adminMessages: {
          ...state.adminMessages,
          errorMessage: action.message,
        },
      };
    default:
      return state;
  }
};

export default messageLogReducer;
