export const SET_USER_ROLES = 'SET_USER_ROLES';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';

export const IS_FLYOUT_LOADING = 'IS_FLYOUT_LOADING';
export const IS_INNER_LOADING = 'IS_INNER_LOADING';
export const IS_AUTH_LOADING = 'IS_AUTH_LOADING';

export const SET_USER = 'SET_USER';
export const SET_USER_CONNECTION = 'SET_USER_CONNECTION';
export const IS_USER_VALID = 'IS_USER_VALID';

export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const GET_ROOM_GROUPS = 'GET_ROOM_GROUPS';

// Auth logging message
export const SET_AUTH_ERROR_MESSAGE = 'SET_AUTH_ERROR_MESSAGE';

export const IS_USERNAME_VALID = 'IS_USERNAME_VALID';
export const IS_PASSWORD_VALID = 'IS_PASSWORD_VALID';
export const IS_REPEAT_PASSWORD_VALID = 'IS_REPEAT_PASSWORD_VALID';
export const IS_KEYCODE_VALID = 'IS_KEYCODE_VALID';

// ADMIN
export const IS_ADMIN_MENU_OPEN = 'IS_ADMIN_MENU_OPEN';
export const IS_FLYOUT_OPEN = 'IS_FLYOUT_OPEN';
export const IS_SNACKBAR_OPEN = 'IS_SNACKBAR_OPEN';
export const IS_ROOM_OPTIONS_OPEN = 'IS_ROOM_OPTIONS_OPEN';

// ADMIN Users Page
export const SET_USERS = 'SET_USERS';

// ADMIN Groups page
export const SET_ROOMS = 'SET_ROOMS';
export const SET_ROOM_GROUPS = 'SET_ROOM_GROUPS';
export const SET_BUILDINGS = 'SET_BUILDINGS';
export const SET_CAMPUSES = 'SET_CAMPUSES';
export const GET_CAMPUSES = 'GET_CAMPUSES';
export const GET_BUILDINGS = 'GET_BUILDINGS';
export const GET_ROOMS = 'GET_ROOMS';
export const GET_DOMAINS = 'GET_DOMAINS';
export const GET_FEATURES = 'GET_FEATURES';
export const GET_ROOM_FEATURES_BY_SINGLE_ROOM = 'GET_ROOM_FEATURES_BY_SINGLE_ROOM';
export const GET_TECH_TYPES = 'GET_TECHNOLOGY_TYPES';
export const GET_ROOM_GROUPS_OF_ROOM = 'GET_ROOM_GROUPS_OF_ROOM';
export const GET_PAIRED_DEVICES_TO_ROOM = 'GET_PAIRED_DEVICES_TO_ROOM';
export const GET_ORG_DETAILS = 'GET_ORG_DETAILS';
export const SET_ROOMS_OF_ROOM_GROUP = 'SET_ROOMS_OF_ROOM_GROUP';
export const GET_SIGNAGE_GROUPS_OF_ROOM = 'GEt_SIGNAGE_GROUPS_OF_ROOM';

// Devices
export const GET_DEVICES = 'GET_DEVICES';
export const GENERATEP_PIN = 'GENERATEP_PIN';
export const GET_DEVICE_ROOMS = 'GET_DEVICE_ROOMS';
export const GET_DEVICE_SUBS = 'GET_DEVICE_SUBS';
export const GET_DEVICE_DETAILS = 'GET_DEVICE_DETAILS';
export const GET_DEVICE_INFO = 'GET_DEVICE_INFO';

export const GET_IPS = 'GET_IPS';
// Admin logging and messaging
export const SET_FLYOUT_ERROR_MESSAGE = 'SET_ADMIN_ERROR_MESSAGE';
// Billing related
export const GET_SUBS = 'GET_SUBS';
export const ADD_SUB = 'ADD_SUB';
// Roles
export const GET_ROLES = 'GET_ROLES';
export const GET_SELECTED_ROLES = 'GET_SELECTED_ROLES';
//floors
export const GET_FLOORS = 'GET_FLOORS';
export const REORDER_FLOORS = 'REORDER_FLOORS';
// Hours
export const GET_HOURS = 'GET_HOURS';
export const GET_FILTERED_DATA = 'GET_FILTERED_DATA';
// Details
export const SET_ROOM_GROUP_DETAILS = 'SET_ROOM_GROUP_DETAILS';
export const SET_ROOM_DETAILS = 'SET_ROOM_DETAILS';
export const SET_BUILDING_DETAILS = 'SET_BUILDING_DETAILS';
export const SET_CAMPUS_DETAILS = 'SET_CAMPUS_DETAILS';
export const SET_NEW_OWNER_EMAIL = 'SET_NEW_OWNER_EMAIL';
export const GET_ORG_IMAGE = 'GET_ORG_IMAGE';
export const DISMISS_HELP = 'DISMISS_HELP';
export const SET_OCCUPANCY_SETTINGS = 'SET_OCCUPANCY_SETTINGS';
export const SET_CAMPUS_OCCUPANCY_SETTINGS = 'SET_CAMPUS_OCCUPANCY_SETTINGS';
export const SET_DETAILS_SUB_NAME = 'SET_DETAILS_SUB_NAME';
export const SET_FINED_SUBS_NAME = 'SET_FINED_SUBS_NAME';

// Subs
export const GET_SUB_DETAIL = 'GET_SUB_DETAIL';
export const SET_SUBSCRIPTION_GROUP_LIST = 'SET_SUBSCRIPTION_GROUP_LIST';

// Signage
export const GET_SLIDES = 'GET_SLIDES';
export const GET_SIGNAGE_GROUPS = 'GET_SIGNAGE_GROUPS';
export const SLIDE_OVERVIEW = 'SLIDE_OVERVIEW';
export const GET_SIGNAGE_GROUP_OVERVIEW = 'GET_SIGNAGE_GROUP_OVERVIEW';
export const GEt_SLIDES_OF_SIGNAGE_GROUP = 'GEt_SLIDES_OF_SIGNAGE_GROUP';
export const GET_ASSIGNEMNTS = 'GEt_ASSIGNEMNTS';
export const REORDER_SLIDES = 'REORDER_SLIDES';

export const OUT_OF_PERMISSION = 'OUT_OF_PERMISSION';
export const GET_ALL_TEXTS = 'GET_ALL_TEXTS';

export const FLYOUT_TRANSITION_TOGGLE = 'FLYOUT_TRANSITION_TOGGLE';

// Kiosk
export const GET_KIOSKS = 'GET_KIOSKS';
export const GET_GROUP_SIGNAGE_GROUPS = 'GET_GROUP_SIGNAGE_GROUPS';

export const ACTIVE_SIGNAGE_ASSIGNMENTS = 'ACTIVE_SIGNAGE_ASSIGNMENTS';
export const ACTIVE_SIGNAGE_NAME = 'ACTIVE_SIGNAGE_NAME';
export const GET_KIOSK_DETAILS = 'GET_KIOSK_DETAILS';
export const UPDATE_KIOSK_SUPERVISE = 'UPDATE_KIOSK_SUPERVISE';
export const REMOVE_SIGNAGE_ASSIGNMENT = 'REMOVE_SIGNAGE_ASSIGNMENT';
export const EDIT_SIGNAGE_ASSIGNMENT = 'EDIT_SIGNAGE_ASSIGNMENT';

// SCM
export const IS_SCM_CONNECTED = 'IS_SCM_CONNECTED';

// Filter
export const SET_MOBILE_FILTER = 'SET_MOBILE_FILTER';
export const REMOVE_SELECTED_ROLE = 'REMOVE_SELECTED_ROLE';
export const EDIT_SELECTED_ROLE = 'EDIT_SELECTED_ROLE';
export const GET_KIOSK_SETTINGS = 'GET_KIOSK_SETTINGS';

// manual enabled kiosks
export const GET_ENABLED_KIOSKS_STORE = 'GET_ENABLED_KIOSKS_STORE';
export const GET_QUESTIONARIES = 'GET_QUESTIONARIES';
export const GET_GLOBAL_MANUAL_RECORD = 'GET_GLOBAL_MANUAL_RECORD';

// Workspaces
export const GET_WORKSPACES = 'GET_WORKSPACES';
export const GET_AREAS = 'GET_AREAS';
export const GET_WORKSPACE_TYPES = 'GET_WORKSPACE_TYPES';
export const GET_WORKSPACE_ENVS = 'GET_WORKSPACE_ENVS';

// orgSettings

export const GET_TENANT_CONFIG = 'GET_TENANT_CONFIG';
export const GET_VISIBILITY_CONFIG = 'GET_VISIBILITY_CONFIG';
