import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import messageLogReducer from './messageLogReducer';
import authenticationReducer from './authenticationReducer';
import adminReducer from './adminReducer';

const authPersistConfig = {
  key: 'authenticationReducer',
  storage,
  whitelist: ['user'],
};

const loggingPersistConfig = {
  key: 'messageLogReducer',
  storage,
  blacklist: ['successMessage', 'errorMessage', 'authMessages', 'setLoading'],
};

const adminPersistConfig = {
  key: 'adminReducer',
  storage,
  blacklist: [
    'toggleAdminMenu',
    'toggleRoomOptions',
    'getBuildings',
    'filteredData',
    'toggleRoomOptions',
    'paging',
    'rooms',
    'permissions',
    'signageAssignments',
    'mobileFilter',
    'selectedRoles',
    'signageName',
    'areas',
  ],
};

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['messageLogReducer', 'adminReducer', 'authenticationReducer'],
};

const rootReducer = combineReducers({
  messageLogReducer: persistReducer(loggingPersistConfig, messageLogReducer),
  authenticationReducer: persistReducer(authPersistConfig, authenticationReducer),
  adminReducer: persistReducer(adminPersistConfig, adminReducer),
});

export default persistReducer(rootPersistConfig, rootReducer);
