import { createStore } from 'redux';
import { persistStore } from 'redux-persist';

import rootReducer from './Reducers';

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), // development purposes
);

const persistor = persistStore(store);

export { store, persistor };
