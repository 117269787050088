import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { WithSuspense } from 'Utils/Global/HOC';

// const Admin = lazy(() => import('Pages/Admin'));

const Admin = WithSuspense(lazy(() => import('Pages/Admin')));
const NotFound404 = WithSuspense(lazy(() => import('Components/Routing/404')));
const Login = WithSuspense(lazy(() => import('Components/Authentication/Login')));
const SSOLogin = WithSuspense(lazy(() => import('Components/Authentication/SSO/SSOLogin')));
const PrivateRouting = WithSuspense(lazy(() => import('Components/Routing/PrivateRouting')));
const TWOFACode = WithSuspense(lazy(() => import('Components/Authentication/SSO/TWOFACode')));
const Users = WithSuspense(lazy(() => import('Components/Admin/Includes/Main/Inners/Users')));
const Sub = WithSuspense(lazy(() => import('Components/Admin/Includes/Main/Inners/Subs/Sub')));
const TWOFALogin = WithSuspense(lazy(() => import('Components/Authentication/SSO/TWOFALogin')));
const Register = WithSuspense(lazy(() => import('Components/Authentication/Register/Resgiter')));
const SSORequired = WithSuspense(lazy(() => import('Components/Authentication/SSO/SSORequired')));
const Devices = WithSuspense(lazy(() => import('Components/Admin/Includes/Main/Inners/Devices')));
const SetupPassword = WithSuspense(lazy(() => import('Components/Authentication/SetupPassword')));
const LeftPain = WithSuspense(lazy(() => import('Components/Authentication/Login/leftPain/index')));
const CreateResetPassword = WithSuspense(
  lazy(() => import('Components/Authentication/SetupPassword/CreateResetPassword')),
);
const ForgotPassword = WithSuspense(
  lazy(() => import('Components/Authentication/ForgotPassword/index')),
);
const LoginExisting = WithSuspense(
  lazy(() => import('Components/Authentication/Login/LoginExisting')),
);
const Rooms = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Rooms')),
);
const RoomGroups = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/RoomGroups')),
);
const Buildings = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Buildings')),
);
const Campuses = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Campuses')),
);
const Campuse = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Campuses/Campuse')),
);
const Building = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Buildings/Building')),
);
const RoomGroup = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/RoomGroups/RoomGroup')),
);
const OrgSettings = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Flyout/Pages/OrgSettings')),
);
const RoomSettings = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Rooms/RoomSettings')),
);
const Room = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Rooms/Room')),
);
const Device = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Devices/Device')),
);
const Dashboard = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Dashboard')),
);
const Subscriptions = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Subs/Subscriptions')),
);
const Slides = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/DigitalSignage/Slides')),
);
const SignageGroups = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/DigitalSignage/SignageGroups')),
);
const Slide = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/DigitalSignage/Slides/Slide')),
);
const SignageGroup = WithSuspense(
  lazy(() =>
    import('Components/Admin/Includes/Main/Inners/DigitalSignage/SignageGroups/SignageGroup'),
  ),
);
const SingageSettings = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/DigitalSignage/SignageSettings')),
);
const Kiosks = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Kiosks')),
);
const Kiosk = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Kiosks/Kiosk')),
);
const KiosksSettings = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Kiosks/KiosksSettings')),
);
const Workspaces = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Workspaces/Workspaces')),
);
const WorkspacesSettings = WithSuspense(
  lazy(() => import('Components/Admin/Includes/Main/Inners/Offices/Workspaces/WorkspacesSettings')),
);
const mainConfig = [
  {
    path: '/',
    exact: true,
    component: () => (
      <Redirect
        to={sessionStorage.getItem('prevPath') ? sessionStorage.getItem('prevPath') : '/dashboard'}
      />
    ),
  },
  {
    path: '/dashboard',
    component: () => (
      <PrivateRouting path="/dashboard">
        <Admin />
      </PrivateRouting>
    ),
    inner: Dashboard,
  },
  {
    path: '/users',
    component: () => (
      <PrivateRouting path="/users">
        <Admin />
      </PrivateRouting>
    ),
    inner: Users,
  },
  {
    path: '/subscriptions',
    component: () => (
      <PrivateRouting path="/subscriptions">
        <Admin />
      </PrivateRouting>
    ),
    inner: Subscriptions,
  },
  {
    path: '/subscription/edit/:index',
    component: () => (
      <PrivateRouting path="/subscription/edit/:index">
        <Admin />
      </PrivateRouting>
    ),
    inner: Sub,
  },
  {
    path: '/rooms',
    component: () => (
      <PrivateRouting path="/rooms">
        <Admin />
      </PrivateRouting>
    ),
    inner: Rooms,
  },
  {
    path: '/room-settings',
    component: () => (
      <PrivateRouting path="/room-settings">
        <Admin />
      </PrivateRouting>
    ),
    inner: RoomSettings,
  },
  {
    path: '/kiosk-settings',
    component: () => (
      <PrivateRouting path="/kiosk-settings">
        <Admin />
      </PrivateRouting>
    ),
    inner: KiosksSettings,
  },
  {
    path: '/groups',
    component: () => (
      <PrivateRouting path="/groups">
        <Admin />
      </PrivateRouting>
    ),
    inner: RoomGroups,
  },
  {
    path: '/buildings',
    component: () => (
      <PrivateRouting path="/buildings">
        <Admin />
      </PrivateRouting>
    ),
    inner: Buildings,
  },
  {
    path: '/campuses',
    component: () => (
      <PrivateRouting path="/campuses">
        <Admin />
      </PrivateRouting>
    ),
    inner: Campuses,
  },
  {
    path: '/kiosks',
    component: () => (
      <PrivateRouting path="/kiosks">
        <Admin />
      </PrivateRouting>
    ),
    inner: Kiosks,
  },
  {
    path: '/kiosk/edit/:kiosk',
    component: () => (
      <PrivateRouting path="/kiosk/edit/:kiosk">
        <Admin />
      </PrivateRouting>
    ),
    inner: Kiosk,
  },
  {
    path: '/workspaces',
    component: () => (
      <PrivateRouting path="/workspaces">
        <Admin />
      </PrivateRouting>
    ),
    inner: Workspaces,
  },
  {
    path: '/workspaces-settings',
    component: () => (
      <PrivateRouting path="/workspaces-settings">
        <Admin />
      </PrivateRouting>
    ),
    inner: WorkspacesSettings,
  },
  {
    path: '/workspace/edit/:kiosk',
    component: () => (
      <PrivateRouting path="/workspace/edit/:kiosk">
        <Admin />
      </PrivateRouting>
    ),
    inner: () => <Kiosk isWorkspace={true} />,
  },
  // Org settings
  {
    path: '/organizational-settings',
    component: () => (
      <PrivateRouting path="/organizational-settings">
        <Admin />
      </PrivateRouting>
    ),
    inner: OrgSettings,
  },
  {
    path: '/campus/edit/:index',
    component: () => (
      <PrivateRouting path="/campus/edit/:index">
        <Admin />
      </PrivateRouting>
    ),
    inner: Campuse,
  },
  {
    path: '/room/edit/:room',
    component: () => (
      <PrivateRouting path="/room/edit/:room">
        <Admin />
      </PrivateRouting>
    ),
    inner: Room,
  },
  {
    path: '/building/edit/:index',
    component: () => (
      <PrivateRouting path="/building/edit/:index">
        <Admin />
      </PrivateRouting>
    ),
    inner: Building,
  },
  {
    path: '/group/edit/:index',
    component: () => (
      <PrivateRouting path="/group/edit/:index">
        <Admin />
      </PrivateRouting>
    ),
    inner: RoomGroup,
  },

  // Devices
  {
    path: '/devices',
    component: () => (
      <PrivateRouting path="/devices">
        <Admin />
      </PrivateRouting>
    ),
    inner: Devices,
  },
  {
    path: '/device/edit/:index',
    component: () => (
      <PrivateRouting path="/device/:index">
        <Admin />
      </PrivateRouting>
    ),
    inner: Device,
  },

  // Authentication
  {
    path: '/login',
    component: () => (
      <Login>
        <LeftPain />
      </Login>
    ),
  },
  {
    path: '/forgot-password-request',
    component: () => (
      <Login>
        <ForgotPassword />
      </Login>
    ),
  },
  {
    path: '/forgot-password',
    component: () => (
      <Login>
        <LeftPain />
      </Login>
    ),
  },
  {
    path: '/reset-password',
    component: () => (
      <Login>
        <SetupPassword>
          <CreateResetPassword resetPassword={true} />
        </SetupPassword>
      </Login>
    ),
  },
  {
    path: '/create-password',
    component: () => (
      <Login>
        <SetupPassword>
          <CreateResetPassword />
        </SetupPassword>
      </Login>
    ),
  },
  {
    path: '/register',
    component: () => (
      <Login>
        <Register />
      </Login>
    ),
  },
  {
    path: '/login-existing',
    component: () => (
      <Login>
        <LoginExisting />
      </Login>
    ),
  },
  {
    path: '/sso-required',
    component: () => (
      <Login>
        <SSORequired />
      </Login>
    ),
  },
  {
    path: '/2fa-login',
    component: () => (
      <Login>
        <TWOFALogin />
      </Login>
    ),
  },
  {
    path: '/sso-login',
    component: () => (
      <Login>
        <SSOLogin />
      </Login>
    ),
  },
  {
    path: '/2fa-code',
    component: () => (
      <Login>
        <TWOFACode />
      </Login>
    ),
  },

  // Digital signage
  {
    path: '/signage-groups',
    component: () => (
      <PrivateRouting path="/signage-groups">
        <Admin />
      </PrivateRouting>
    ),
    inner: SignageGroups,
  },
  {
    path: '/slides',
    component: () => (
      <PrivateRouting path="/slides">
        <Admin />
      </PrivateRouting>
    ),
    inner: Slides,
  },
  {
    path: '/slide/edit/:index',
    component: () => (
      <PrivateRouting path="/slide/edit/:index">
        <Admin />
      </PrivateRouting>
    ),
    inner: Slide,
  },
  {
    path: '/signage-group/edit/:index',
    component: () => (
      <PrivateRouting path="/signage-group/edit/:index">
        <Admin />
      </PrivateRouting>
    ),
    inner: SignageGroup,
  },
  {
    path: '/signage-settings',
    component: () => (
      <PrivateRouting path="/signage-settings">
        <Admin />
      </PrivateRouting>
    ),
    inner: SingageSettings,
  },
  {
    path: '/access-denied',
    exact: true,
    component: () => (
      <NotFound404
        titleone="You don't have permission to"
        titletwo="access this page."
        helpermessage="Contact your administrator."
      />
    ),
  },
  // Not found page
  {
    path: '*',
    exact: true,
    component: () => (
      <NotFound404
        titleone="We can't find the page"
        titletwo="you are looking for."
        helpermessage="Error code: 404"
      />
    ),
  },
];

export { mainConfig };
