import * as actionTypes from '../ActionTypes';

const initialState = {
  user: {
    userData: null,
  },
  isUserValid: false,
  validationControl: {
    isUsernameValid: false,
    isPasswordValid: false,
    isRetypePasswordValid: false,
    isKeycodeValid: false,
  },
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          userData: action.user,
        },
      };
    case actionTypes.SET_USER_CONNECTION:
      return {
        ...state,
        user: {
          ...state.user,
          userData: {
            ...state.user.userData,
            tenantId: action.tenantId,
          },
        },
      };
    case actionTypes.SET_USER_TOKEN:
      return {
        ...state,
        user: {
          ...state.user,
          userData: {
            ...state.user.userData,
            token: action.token,
          },
        },
      };
    case actionTypes.IS_USER_VALID:
      return {
        ...state,
        isUserValid: action.isValid,
      };
    case actionTypes.IS_USERNAME_VALID:
      return {
        ...state,
        validationControl: {
          ...state.validationControl,
          isUsernameValid: action.isValid,
        },
      };
    case actionTypes.IS_PASSWORD_VALID:
      return {
        ...state,
        validationControl: {
          ...state.validationControl,
          isPasswordValid: action.isValid,
        },
      };
    case actionTypes.IS_REPEAT_PASSWORD_VALID:
      return {
        ...state,
        validationControl: {
          ...state.validationControl,
          isRetypePasswordValid: action.isValid,
        },
      };
    case actionTypes.IS_KEYCODE_VALID:
      return {
        ...state,
        validationControl: {
          ...state.validationControl,
          isKeycodeValid: action.isValid,
        },
      };
    default:
      return state;
  }
};

export default authenticationReducer;
