import * as actionTypes from '../ActionTypes';

const initialState = {
  toggleAdminMenu: false,
  toggleRoomOptions: false,
  toggleFlyout: false,
  toggleSnackbar: {
    isSnackBarOpen: false,
    message: '',
  },
  users: [],
  rooms: [],
  roomGroupsofRoom: [],
  roomsOfRoomGroup: [],
  signageGroupsOfRoom: [],
  permissions: [],
  getRooms: [],
  roomGroups: [],
  getRoomGroups: [],
  buildings: [],
  getBuildings: [],
  campuses: [],
  getCampuses: [],
  subs: [],
  roles: [],
  selectedRoles: {
    collectedData: [],
    dataToShow: [],
  },
  floors: [],
  reorderedFloors: [],
  hours: [],
  filteredData: [],
  domains: [],
  features: [],
  featuresByRoom: [],
  technologies: [],
  devices: [],
  roomPairedDevices: [],
  ips: [],

  deviceRooms: [],
  deviceSubs: [],
  reorderedSlides: [],

  // Details
  details: {
    roomGroup: {},
    room: {},
    buildings: {},
    campus: {},
    organization: {},
    device: {},
    sub: {},
    slide: {},
    signageGroup: {},
    kiosk: {},
    occupancySettings: {},
  },
  dismissHelp: false,

  email: '',

  // Slides
  slides: [],
  signageGroups: [],
  signageGroupSlides: [],
  assignments: [],

  permitted: false,
  allTexts: [],
  flyoutTransitionToggle: 'flyout-transition',
  kiosks: [],
  groupSignageGroups: [],
  signageAssignments: [],
  signageName: '',
  mobileFilter: {},
  kioskSettings: {},
  enabledKiosks: [],
  questionaries: [],
  globalManualRecord: null,
  workspaces: [],
  areas: [],
  tenantConfig: [],
  visibilityConfig: {},
  workspacesSettingsTypes: [],
  workspacesSettingsEnvs: [],
  subscriptionGroupList: [],
};
const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_AREAS:
      return {
        ...state,
        areas: action.payload,
      };
    case actionTypes.GET_WORKSPACES:
      return {
        ...state,
        workspaces: action.payload,
      };
    case actionTypes.GET_GLOBAL_MANUAL_RECORD:
      return {
        ...state,
        globalManualRecord: action.payload,
      };
    case actionTypes.GET_QUESTIONARIES:
      return {
        ...state,
        questionaries: action.payload,
      };
    case actionTypes.GET_ENABLED_KIOSKS_STORE:
      return {
        ...state,
        enabledKiosks: action.payload,
      };
    case actionTypes.GET_KIOSK_SETTINGS:
      return {
        ...state,
        kioskSettings: action.payload,
      };
    case actionTypes.SET_MOBILE_FILTER:
      return {
        ...state,
        mobileFilter: action.filter,
      };
    case actionTypes.SET_CAMPUS_OCCUPANCY_SETTINGS:
      return {
        ...state,
        details: {
          ...state.details,
          campus: {
            ...state.details.campus,
            occPercantage: action.payload,
          },
        },
      };
    case actionTypes.SET_OCCUPANCY_SETTINGS:
      return {
        ...state,
        details: {
          ...state.details,
          occupancySettings: {
            ...action.payload,
          },
        },
      };
    case actionTypes.UPDATE_KIOSK_SUPERVISE:
      return {
        ...state,
        details: {
          ...state.details,
          kiosk: {
            ...state.details.kiosk,
            isSupervised: action.iSupervised,
          },
        },
      };
    case actionTypes.GET_KIOSK_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          kiosk: action.details,
        },
      };
    case actionTypes.ACTIVE_SIGNAGE_NAME:
      return {
        ...state,
        signageName: action.name,
      };
    case actionTypes.ACTIVE_SIGNAGE_ASSIGNMENTS:
      return {
        ...state,
        signageAssignments: action.assignments,
      };
    case actionTypes.REMOVE_SIGNAGE_ASSIGNMENT:
      return {
        ...state,
        signageAssignments: state.signageAssignments.filter(
          elem => action.assignments !== elem.objectId,
        ),
      };
    case actionTypes.EDIT_SIGNAGE_ASSIGNMENT:
      return {
        ...state,
        signageAssignments: action.assignments,
      };
    case actionTypes.GET_GROUP_SIGNAGE_GROUPS:
      return {
        ...state,
        groupSignageGroups: action.signageGroups,
      };
    case actionTypes.GET_KIOSKS:
      return {
        ...state,
        kiosks: action.kiosks,
      };
    case actionTypes.FLYOUT_TRANSITION_TOGGLE:
      return {
        ...state,
        flyoutTransitionToggle: action.toggle,
      };
    case actionTypes.GET_ALL_TEXTS:
      return {
        ...state,
        allTexts: action.texts,
      };
    case actionTypes.OUT_OF_PERMISSION:
      return {
        ...state,
        permitted: action.permission,
      };
    case actionTypes.SET_USER_ROLES:
      return {
        ...state,
        permissions: action.roles,
      };
    // digital signage
    case actionTypes.GET_ASSIGNEMNTS:
      return {
        ...state,
        assignments: action.assignments,
      };
    case actionTypes.GET_SIGNAGE_GROUPS:
      return {
        ...state,
        signageGroups: action.groups,
      };
    case actionTypes.GET_SLIDES:
      return {
        ...state,
        slides: action.slides,
      };
    // digital signage
    case actionTypes.DISMISS_HELP:
      return {
        ...state,
        dismissHelp: action.toggle,
      };
    case actionTypes.GEt_SLIDES_OF_SIGNAGE_GROUP:
      return {
        ...state,
        signageGroupSlides: action.slides,
      };
    // Devices
    case actionTypes.GET_DEVICES:
      return {
        ...state,
        devices: action.devices,
      };

    case actionTypes.GET_DEVICE_SUBS:
      return {
        ...state,
        deviceSubs: action.subs,
      };
    case actionTypes.GET_DEVICE_ROOMS:
      return {
        ...state,
        deviceRooms: action.rooms,
      };
    case actionTypes.GET_DEVICE_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          device: {
            ...state.details.device,
            details: action.details,
          },
        },
      };
    case actionTypes.GET_DEVICE_INFO:
      return {
        ...state,
        details: {
          ...state.details,
          device: {
            ...state.details.device,
            info: action.info,
          },
        },
      };
    // Details
    case actionTypes.GENERATEP_PIN:
      return {
        ...state,
        details: {
          ...state.details,
          device: {
            ...state.details.device,
            pin: action.pin,
          },
        },
      };
    case actionTypes.GET_ORG_IMAGE:
      return {
        ...state,
        logoUrl: action.url,
      };
    case actionTypes.GET_SIGNAGE_GROUP_OVERVIEW:
      return {
        ...state,
        details: {
          ...state.details,
          signageGroup: action.details,
        },
      };
    case actionTypes.SLIDE_OVERVIEW:
      return {
        ...state,
        details: {
          ...state.details,
          slide: action.details,
        },
      };
    case actionTypes.GET_SUB_DETAIL:
      return {
        ...state,
        details: {
          ...state.details,
          sub: action.details,
        },
      };
    case actionTypes.SET_SUBSCRIPTION_GROUP_LIST:
      return {
        ...state,
        subscriptionGroupList: action.payload,
      };
    case actionTypes.SET_DETAILS_SUB_NAME:
      return {
        ...state,
        details: {
          ...state.details,
          sub: {
            ...state.details.sub,
            name: action.payload,
          },
        },
      };

    case actionTypes.IS_SCM_CONNECTED:
      return {
        ...state,
        details: {
          ...state.details,
          organization: {
            ...state.details.organization,
            isConnectedSCM: action.isConnected,
          },
        },
      };
    case actionTypes.GET_ORG_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          organization: action.details,
        },
      };
    case actionTypes.SET_CAMPUS_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          campus: action.details,
        },
      };
    case actionTypes.SET_BUILDING_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          buildings: action.details,
        },
      };
    case actionTypes.SET_ROOM_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          room: action.details,
        },
      };
    case actionTypes.SET_ROOM_GROUP_DETAILS:
      return {
        ...state,
        details: {
          ...state.details,
          roomGroup: action.details,
        },
      };
    // Details
    case actionTypes.GET_PAIRED_DEVICES_TO_ROOM:
      return {
        ...state,
        roomPairedDevices: action.devices,
      };
    case actionTypes.GET_ROOM_GROUPS:
      return {
        ...state,
        getRoomGroups: action.roomGroups,
      };
    case actionTypes.GET_ROOM_GROUPS_OF_ROOM:
      return {
        ...state,
        roomGroupsofRoom: action.roomGroups,
      };
    case actionTypes.GET_SIGNAGE_GROUPS_OF_ROOM:
      return {
        ...state,
        signageGroupsOfRoom: action.signageGroups,
      };
    case actionTypes.SET_ROOMS_OF_ROOM_GROUP:
      return {
        ...state,
        roomsOfRoomGroup: action.rooms,
      };
    case actionTypes.GET_FEATURES:
      return {
        ...state,
        features: action.features,
      };
    case actionTypes.GET_ROOM_FEATURES_BY_SINGLE_ROOM:
      return {
        ...state,
        featuresByRoom: action.featuresByRoom,
      };
    case actionTypes.GET_TECH_TYPES:
      return {
        ...state,
        technologies: action.tech,
      };
    case actionTypes.GET_DOMAINS:
      return {
        ...state,
        domains: action.domains,
      };
    case actionTypes.GET_FILTERED_DATA:
      return {
        ...state,
        filteredData: action.data,
      };
    case actionTypes.IS_ADMIN_MENU_OPEN:
      return {
        ...state,
        toggleAdminMenu: action.value,
      };
    case actionTypes.IS_FLYOUT_OPEN:
      return {
        ...state,
        toggleFlyout: !state.toggleFlyout,
      };
    case actionTypes.IS_SNACKBAR_OPEN:
      return {
        ...state,
        toggleSnackbar: {
          ...state.toggleSnackbar,
          isSnackBarOpen: !state.toggleSnackbar.isSnackBarOpen,
          message: action.message,
        },
      };
    case actionTypes.IS_ROOM_OPTIONS_OPEN:
      return {
        ...state,
        toggleRoomOptions: action.value,
      };
    case actionTypes.SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case actionTypes.SET_ROOMS:
      return {
        ...state,
        rooms: action.rooms,
      };
    case actionTypes.SET_ROOM_GROUPS:
      return {
        ...state,
        roomGroups: action.roomGroups,
      };
    case actionTypes.SET_BUILDINGS:
      return {
        ...state,
        buildings: action.buildings,
      };
    case actionTypes.GET_BUILDINGS:
      return {
        ...state,
        getBuildings: action.buildings,
      };
    case actionTypes.SET_CAMPUSES:
      return {
        ...state,
        campuses: action.campuses,
      };
    case actionTypes.GET_CAMPUSES:
      return {
        ...state,
        getCampuses: action.campuses,
      };
    case actionTypes.GET_SUBS:
      return {
        ...state,
        subs: action.subs,
      };
    case actionTypes.GET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case actionTypes.REMOVE_SELECTED_ROLE:
      return {
        ...state,
        selectedRoles: {
          collectedData: state.selectedRoles.collectedData.filter(
            elem => elem.roleId !== action.roleId,
          ),
          dataToShow: state.selectedRoles.dataToShow.filter(elem => elem.id !== action.roleId),
        },
      };
    case actionTypes.EDIT_SELECTED_ROLE:
      return {
        ...state,
        selectedRoles: {
          collectedData: state.selectedRoles.collectedData.filter(elem => {
            if (elem.roleId === action.payload.currentId) {
              elem.roleId = action.payload.roleId;
              elem.objectsId = action.payload.objectsId;
              elem.name = action.payload.name;
            }
            return elem;
          }),
          dataToShow: state.selectedRoles.dataToShow.filter(elem => {
            if (elem.id === action.payload.currentId) {
              elem.id = action.payload.roleId;
              elem.name = action.payload.name;
            }
            return elem;
          }),
        },
      };
    case actionTypes.GET_SELECTED_ROLES:
      return {
        ...state,
        selectedRoles: action.roles,
      };
    case actionTypes.GET_FLOORS:
      return {
        ...state,
        floors: action.floors,
      };
    case actionTypes.REORDER_FLOORS:
      return {
        ...state,
        reorderedFloors: action.floors,
      };
    case actionTypes.REORDER_SLIDES:
      return {
        ...state,
        reorderedSlides: action.slides,
      };
    case actionTypes.GET_HOURS:
      return {
        ...state,
        hours: action.hours,
      };
    case actionTypes.GET_ROOMS:
      return {
        ...state,
        getRooms: action.rooms,
      };
    case actionTypes.GET_IPS:
      return {
        ...state,
        ips: action.ips,
      };
    case actionTypes.SET_NEW_OWNER_EMAIL:
      return {
        ...state,
        newEmail: action.email,
      };
    case actionTypes.GET_TENANT_CONFIG:
      return {
        ...state,
        tenantConfig: action.payload,
      };
    case actionTypes.GET_VISIBILITY_CONFIG:
      return {
        ...state,
        visibilityConfig: action.payload,
      };
    case actionTypes.GET_WORKSPACE_TYPES:
      return {
        ...state,
        workspacesSettingsTypes: action.payload,
      };
    case actionTypes.GET_WORKSPACE_ENVS:
      return {
        ...state,
        workspacesSettingsEnvs: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
